@import '../../assets/styles/colors';

.BuildingsView {
  margin        : 0 auto;
  padding       : 30px 0;
  max-width     : 70vw;
  display       : flex;
  flex-direction: column;
  align-items   : center;
  position      : relative;

  @media screen and (min-width: 1024px) {
    &::before {
      content         : '';
      width           : 100vw;
      height          : 100vh;
      position        : fixed;
      top             : 60px;
      left            : 0;
      background-image: linear-gradient(90deg, rgba(39, 26, 35, 0.5074404761904762) 0%, rgba(39, 26, 35, 1) 20%, rgba(39, 26, 35, 1) 80%, rgba(39, 26, 35, 0.5018382352941176) 100%), url('../../assets/images/background_shoptitans_landscape.jpg');
      background-size : cover;
    }
  }

  &__title {
    margin        : 0;
    margin-bottom : 50px;
    font-size     : 45px;
    letter-spacing: 2px;
    color         : #fff3d6;
    position      : relative;

    &::before {
      content   : '';
      width     : 100%;
      height    : 1px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0), gold, rgba(0, 0, 0, 0));
      position  : absolute;
      bottom    : -1px;
      left      : 0;
    }
  }

  &__list {
    width          : 100%;
    display        : flex;
    justify-content: space-between;
    flex-wrap      : wrap;

    .BuildingCard {
      margin-bottom: 60px;
      width        : 390px;

      @media screen and (max-width: 1366px) {
        width: 280px;
      }

      @media screen and (max-width: 768px) {
        width        : 100%;
        margin-bottom: 70px;
      }

      @media screen and (max-width: 600px) {
        margin-bottom: 50px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 80vw;

    &__title {
      margin-bottom: 35px;
      font-size    : 35px;
    }
  }
}