@import '../../assets/styles/functions';
@import '../../assets/styles/colors';

.PlayersList {
  margin : 0;
  padding: 20px 30px 20px 50px;
  width  : db(500);

  position: relative;
  overflow: hidden;

  background-color: $primaryBG;
  border          : 5px solid $primaryBorder;
  border-radius   : 30px;
  box-shadow      : 0 0 40px -15px gold;
  list-style      : decimal;

  &_pending::after {
    content: '';

    position: absolute;
    top     : 0;
    right   : 0;
    bottom  : 0;
    left    : 0;
    z-index : 100;

    background-color: transparentize($primaryBG, 0.5);
  }

  &__player {
    width    : 100%;
    color    : $textColor;
    font-size: 25px;
    position : relative;
    cursor   : pointer;

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }

    &::before {
      content: '';

      position: absolute;
      bottom  : -1px;
      left    : 0;

      width : 100%;
      height: 1px;

      background: linear-gradient(90deg, rgba(0, 0, 0, 0), gold, rgba(0, 0, 0, 0));
      transition: all 0.3s ease;
    }

    &:hover::before {
      height: 3px;
      bottom: -3px;
    }

    &-wrapper {
      display        : flex;
      justify-content: space-between;
      position       : relative;
      transition     : padding 0.2s ease;
    }
  }

  &__name,
  &__contributions {
    display    : inline-block;
    margin     : 0;
    white-space: nowrap;
  }

  &__popup {
    box-sizing: border-box;

    min-width: db(600);
    padding  : 0 40px;

    &-line {
      width          : 100%;
      display        : flex;
      justify-content: space-between;

      font-size  : 28px;
      font-weight: 500;
      color      : $textColor;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    &-item-name {
      color: $primaryYellow;
    }
  }

  @media screen and (min-width: 1921px) {
    &__player {
      font-size: 30px;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }

  @media screen and (max-width: 1366px) {
    &__player {
      font-size: 20px;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }

    &__popup {
      min-width: d(600);
    }
  }

  @media screen and (max-width: 1024px) {
    width: ds(350);

    &__popup {
      min-width: ds(550);
    }
  }

  @media screen and (max-width: 768px) {
    width: t(350);

    &__popup {
      min-width: t(500);
    }
  }

  @media screen and (max-width: 600px) {
    width: mb(350);

    &__player {
      font-size: 18px;
    }

    &__popup {
      min-width: 100%;
      padding  : 0;
    }
  }

  @media screen and (max-width: 375px) {
    &__popup {
      &-line {
        font-size: 20px;
      }
    }
  }
}