.App {
  box-sizing      : border-box;
  padding-top     : 100px;
  min-height      : 100vh;
  text-align      : center;
  background-color: #271a23;

  .PageHeader {
    position: fixed;
    top     : 0;
    left    : 0;
    width   : 100%;
  }

  @media screen and (max-width: 1024px) {
    padding-top: 70px;
  }
}