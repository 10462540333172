@import '../../assets/styles/colors';

.Timer {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  color         : $textColor;
  letter-spacing: 1px;

  &__title {
    margin       : 0;
    margin-bottom: 15px;
    font-size    : 35px;
  }

  &__timer {
    margin   : 0;
    font-size: 30px;
  }

  @media screen and (max-width: 360px) {
    &__title {
      margin-bottom: 10px;
      font-size    : 30px;
    }
  
    &__timer {
      font-size: 25px;
    }
  }
}