@import '../../assets/styles/colors';

.YellowButton {
  width          : fit-content;
  display        : flex;
  justify-content: center;
  align-items    : center;
  padding        : 1rem 2rem;
  color          : #ffffff;
  background     : $buttonYellowGrad;
  border         : none;
  border-radius  : 10px;
  font-size      : 20px;
  text-shadow    : 0 0 3px #000000;
  box-shadow     : inset 0 -4px 0 0 $primaryYellow, 0 0 10px 0 $secondaryYellow;
  cursor         : pointer;
  transition     : box-shadow 0.2s ease;

  &:hover {
    box-shadow: inset 0 -4px 0 0 $primaryYellow, 0 0 20px 0 $secondaryYellow;
  }
}