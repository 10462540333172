@import '../../assets/styles/colors';

.PopUpWindow {
  box-sizing: border-box;

  position: fixed;
  top     : 50%;
  left    : 50%;
  z-index : 999;

  padding       : 30px 40px;
  display       : flex;
  flex-direction: column;

  background-color: $primaryBG;
  border          : 5px solid $primaryBorder;
  border-radius   : 20px;
  transform       : translate(-50%, -50%);

  &__title {
    position: relative;

    margin       : 0;
    margin-bottom: 20px;

    font-size  : 40px;
    font-weight: 400;
    color      : $textColor;

    &::before {
      content: "";

      position: absolute;
      bottom  : -1px;
      left    : 0;

      width : 100%;
      height: 1px;

      background: linear-gradient(90deg, rgba(0, 0, 0, 0), gold, rgba(0, 0, 0, 0));
    }
  }

  &__message {
    margin       : 0;
    margin-bottom: 40px;
    font-size    : 25px;
    color        : $textColor;
  }

  &__close {
    width          : 35px;
    height         : 35px;
    background     : url('../../assets/images/close.svg') no-repeat 50% 50%;
    background-size: cover;
    position       : absolute;
    top            : 15px;
    right          : 15px;
    cursor         : pointer;
    transition     : transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .YellowButton {
    align-self: center;
  }

  @media screen and (max-width: 600px) {
    padding: 0 20px;
    width  : 100%;
    height : 100vh;

    justify-content: center;

    border-radius: unset;
    border       : none;

    &__close {
      top            : 80px;
    }
  }
}