@import '../../assets/styles/colors';

.ContributionsView {
  margin        : 0 auto;
  padding       : 30px 0;
  max-width     : 70vw;
  height        : 100%;
  display       : flex;
  flex-direction: column;
  align-items   : center;
  position      : relative;

  @media screen and (min-width: 1024px) {
    &::before {
      content         : '';
      width           : 100vw;
      height          : 100vh;
      position        : fixed;
      top             : 60px;
      left            : 0;
      background-image: linear-gradient(90deg, rgba(39, 26, 35, 0.5074404761904762) 0%, rgba(39, 26, 35, 1) 20%, rgba(39, 26, 35, 1) 80%, rgba(39, 26, 35, 0.5018382352941176) 100%), url('../../assets/images/background_shoptitans_landscape.jpg');
      background-size : cover;
    }
  }

  &__title {
    margin        : 0;
    font-size     : 45px;
    letter-spacing: 2px;
    color         : #fff3d6;
    position      : relative;

    &:not(:first-of-type) {
      margin-top: 50px;
    }

    &::before {
      content   : '';
      width     : 100%;
      height    : 1px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0), gold, rgba(0, 0, 0, 0));
      position  : absolute;
      bottom    : -1px;
      left      : 0;
    }
  }

  &__add-contributions {
    width          : 35px;
    height         : 35px;
    background     : url('../../assets/images/goals.png') no-repeat 50% 50%;
    background-size: cover;
    position       : absolute;
    top            : 20px;
    right          : 20px;
    cursor         : pointer;
    transition     : transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .CreateContributionsForm {
    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 9999;
  }

  @media screen and (max-width: 1366px) {
    &__title {
      font-size: 40px;

      &:not(:first-of-type) {
        margin-top: 40px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 85vw;
  }

  @media screen and (max-width: 600px) {
    max-width: 90vw;

    &__title {
      font-size: 27px;

      &:not(:first-of-type) {
        margin-top: 30px;
      }
    }
  }

  @media screen and (max-width: 360px) {
    &__title {
      font-size: 25px;
    }
  }
}