@import './assets/styles/colors';

@font-face {
  font-family: 'Curse Casual';
  font-weight: 400;
  font-style : normal;
  src        : local('Curse Casual'), url('./assets/fonts/cursecasual.otf') format('opentype');
}

body {
  margin                 : 0;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height             : 100vh;
  overflow-x: hidden;

  * {
    font-family: 'Curse Casual', sans-serif;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primaryBorder;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: $primaryBG;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, monospace;
}

.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}