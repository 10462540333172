@import '../../assets/styles/colors';
@import '../../assets/styles/functions';

.BuildingCard {
  box-sizing     : border-box;
  padding        : 15px 30px;
  height         : 170px;
  background     : linear-gradient(0deg, $primaryBG, $secondaryBG);
  border         : 4px solid $primaryBorder;
  border-radius  : 15px;
  box-shadow     : 0 5px 0 0 $primaryBG;
  position       : relative;
  transition     : transform 0.15s linear;
  color          : $textColor;
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
  align-items    : flex-end;
  filter         : drop-shadow(0 0 5px gold);
  animation      : scale-in 0.3s ease;

  &__image-container {
    box-sizing     : border-box;
    width          : 150px;
    height         : 150px;
    background     : url('../../assets/images/item-bg.webp') no-repeat 50% 50%;
    background-size: cover;
    border         : 4px solid $primaryBorder;
    border-radius  : 50%;
    position       : absolute;
    top            : 0;
    left           : 0;
    transform      : translate(-15%, -15%);
    display        : flex;
    justify-content: center;
    align-items    : center;
  }

  &__image {
    box-sizing: border-box;
    width     : 110px;
    height    : 110px;
    object-fit: contain;
  }

  &__title {
    box-sizing    : border-box;
    margin        : 0;
    margin-bottom : 5px;
    padding-left  : 80px;
    width         : 100%;
    font-size     : 35px;
    letter-spacing: 2px;
    text-align    : right;
    border-bottom : 4px solid $primaryBorder;
  }

  &__footer {
    display       : flex;
    flex-direction: row-reverse;
    align-items   : center;
  }

  &__level {
    margin     : 0;
    margin-left: 10px;
    font-size  : 28px;
    color      : #FDBF1A;
  }

  &:hover {
    transform: translateY(-5px);
  }

  @media screen and (max-width: 1366px) {
    padding: 10px 20px;
    height : 130px;

    &__image-container {
      width    : 120px;
      height   : 120px;
      transform: translate(-20%, -20%);
    }

    &__image {
      width : 80px;
      height: 80px;
    }

    &__title {
      padding-left: 75px;
      font-size   : 25px;
    }

    &__level {
      font-size: 23px;
    }
  }

  @media screen and (max-width: 768px) {
    box-shadow: none;

    &__image-container {
      width    : 170px;
      height   : 170px;
      transform: translate(-40px, -20px);
    }

    &__image {
      width : 130px;
      height: 130px;
    }

    &__title {
      padding-left: 0;
      font-size   : 35px;
    }

    &__level {
      font-size: 28px;
    }

    &:hover {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 600px) {
    padding      : 5px 10px;
    height       : 80px;
    border-radius: 10px;

    &__image-container {
      width    : 100px;
      height   : 100px;
      transform: translate(-22px, -14px);
    }

    &__image {
      width : 70px;
      height: 70px;
    }

    &__title {
      padding-left : 80px;
      font-size    : 22px;
      text-overflow: ellipsis;
      overflow     : hidden;
      white-space  : nowrap;
    }

    &__level {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 360px) {
    &__title {
      font-size   : 20px;
      padding-left: 73px;
    }
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}