@import '../../assets/styles/functions';
@import '../../assets/styles/colors';

.MainView {
  margin         : 0 auto;
  padding        : db(700) 10vw 100px;
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
  position       : relative;
  background     : url('../../assets/images/banana-minion.png') no-repeat 50% 10px;
  background-size: 37%;

  &__players-list {
    width   : fit-content;
    height  : fit-content;
    position: relative;
    z-index : 10;

    .MainView__title-wrapper {
      display        : flex;
      justify-content: center;
      position       : relative;

      .MainView__add-player-button {
        width          : 40px;
        height         : 40px;
        background     : url('../../assets/images/plus.svg') no-repeat 50% 50%;
        background-size: cover;
        position       : absolute;
        top            : 5px;
        right          : 30px;
        cursor         : pointer;
        transition     : transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .MainView__title {
      margin        : 0;
      margin-bottom : 25px;
      font-size     : 35px;
      color         : $textColor;
      letter-spacing: 2px;
      user-select   : none;
      cursor        : default;

      &_for-gm {
        transition: color 0.3s ease;
        cursor    : pointer;

        &:hover {
          color: gold;
        }
      }
    }

    .MainView__title-icon {
      margin-right   : 10px;
      width          : 40px;
      height         : 40px;
      background     : url('../../assets/images/bugle3.png') no-repeat 50% 50%;
      background-size: cover;

      &:last-of-type {
        margin-left: 7px;
        transform  : scale(-1, 1);
      }
    }
  }

  &__banana {
    width          : db(300);
    height         : db(360);
    background     : url('../../assets/images/one-banana.png') no-repeat 50% 50%;
    background-size: cover;
    position       : absolute;
    filter         : drop-shadow(0 0 15px $primaryBlue);

    &_one {
      top      : db(-30);
      left     : db(-80);
      transform: rotate(200deg);
    }

    &_two {
      top      : db(350);
      left     : db(-30);
      transform: rotate(225deg);
    }

    &_three {
      top      : db(240);
      left     : db(-60);
      transform: rotate(190deg);
    }

    &_four {
      top      : db(-10);
      left     : db(-170);
      transform: rotate(60deg);
    }

    &_five {
      top      : db(460);
      left     : db(-40);
      transform: rotate(45deg);
    }

    &_six {
      top      : db(350);
      left     : db(-150);
      transform: scale(-1, 1) rotate(50deg);
    }

    &_seven {
      top      : db(690);
      left     : db(-90);
      transform: rotate(225deg);
    }
  }

  &__bananas-left {
    position: fixed;
    top     : 60px;
    left    : 0;
  }

  &__bananas-right {
    position : fixed;
    top      : 60px;
    right    : 0;
    transform: scale(-1, 1);
  }

  .Timer {
    margin-bottom: 60px;
  }

  @media screen and (max-width: 1366px) {
    padding-top: d(480);

    &__title {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 1024px) {
    padding        : ds(500) 10vw 100px;
    background-size: 45%;
  }

  @media screen and (max-width: 768px) {
    padding        : t(500) 10vw 100px;
    background-size: 60%;

    &__bananas-left {
      display: none;
    }

    &__bananas-right {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    padding            : mb(580) 10vw 50px;
    background-position: 50% 0;
    background-size    : 100%;

    &__title {
      font-size: 25px;
    }

    &__title-icon {
      width : 35px;
      height: 35px;
    }

    .Timer {
      margin-bottom: 30px;
    }
  }
}