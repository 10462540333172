@import '../../assets/styles/functions';
@import '../../assets/styles/colors';

.MobileMenu {
  height: fit-content;

  &__burger {
    width          : 40px;
    height         : 30px;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
  }

  &__line {
    width           : 100%;
    height          : 5px;
    background-color: $textColor;
  }

  &__menu {
    box-sizing      : border-box;
    padding         : 60px calc(100vw * (30/414));
    width           : calc(100vw * (300 / 414));
    height          : 100vh;
    position        : fixed;
    top             : 0;
    right           : 0;
    background-color: $primaryBG;
    border-left     : 1px solid $primaryBorder;
    z-index         : 999;
    transition      : transform 0.3s ease-out;

    .NavMenu__item {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }

    .NavMenu__text {
      font-size: 35px;
    }

    &_closed {
      transform: translateX(100%);
    }
  }

  &__close {
    width          : 30px;
    height         : 30px;
    position       : absolute;
    top            : 15px;
    right          : 15px;
    background     : url('../../assets/images/close.svg') no-repeat 50% 50%;
    background-size: cover;
  }

  &__bg-cover {
    width          : 100vw;
    height         : 100vh;
    position       : fixed;
    top            : 0;
    left           : 0;
    background     : rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(8px) saturate(30%);
    z-index        : 998;
    transition     : all 0.3s ease;
    opacity        : 1;

    &_hidden {
      opacity   : 0;
      visibility: hidden;
    }
  }

  &__game-logo {
    width     : 120px;
    height    : 70px;
    object-fit: cover;
    position  : absolute;
    bottom    : 40px;
    left      : 50%;
    transform : translateX(-50%);
  }

  @media screen and (min-width: 601px) {
    &__menu {
      padding: 60px calc(100vw * (30/414));
      width  : t(300);
    }
  }
}